import genericAlertIcon from 'images/alert.svg';
import flashOff from 'images/flash-off.svg';
import chargingIcon from 'images/flash.svg';
import greenflash from 'images/green-flash2.svg';
import greyFlash from 'images/grey-flash.svg';
import ParkedIcon from 'images/Rightsymbolgreenback.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import {
	AutoDockingController,
	AutoDockingStatus,
} from '../../navigation/autoDocking/useAutoDockingController';
import './index.scss';

import { setParameter } from 'actions/setParam';
import HelpCenterAnchor from 'components/helpCenterAnchor';
import Snackbar from 'components/snackbar';
import chargingInProgressIcon from 'images/charging.svg';
import dockingStagePositioningIcon from 'images/docking-positioning.svg';
import { connect, ConnectedProps } from 'react-redux';
import { AppRootState } from 'reducers';
import { translationWithErrorHandling } from '../../../../translation/handleMissingTranslation';

const reduxConnector = connect(
	(state: AppRootState) => ({
		isAutoParking: state.sessionState.isAutoParking,
		robotStatus: state.sessionState.robotStatus,
	}),
	{ setParameter }
);

type PropsFromRedux = ConnectedProps<typeof reduxConnector>;
type PropsFromParent = {
	status: AutoDockingStatus;
};
type ComponentProps = PropsFromRedux & PropsFromParent;

type IConfig = {
	title: string;
	label: string;
	icon: string;
	actionRequired: React.ReactNode;
	iconDisplayRule: 'Both' | 'Minimized' | 'Regular';
};

function AutoDockingStatusIndicator({ status, isAutoParking, robotStatus }: ComponentProps) {
	const intl = useIntl();

	function getConfig(
		{ stage, state, customData }: AutoDockingController['status'],
		isAutoParking: boolean
	) {
		const actionRequiredToStop = (
			<>{translationWithErrorHandling(intl, 'autoDockingStatus.pressAnyKeyToStop')}</>
		);

		const failedActionRequired = (
			<>
				<span>
					{translationWithErrorHandling(
						intl,
						'autoDockingStatus.makeSureTheChargingDockIsPoweredOn'
					)}{' '}
				</span>
				{translationWithErrorHandling(intl, 'autoDockingStatus.ifTheProblemPersistsVisitToThe')}{' '}
				<HelpCenterAnchor />
			</>
		);
		const DockingStatusConfig: Record<
			AutoDockingController['status']['stage'],
			Partial<IConfig & Record<AutoDockingController['status']['state'], Partial<IConfig>>> | null
		> = {
			DETECTING: {
				title: translationWithErrorHandling(intl, 'autoDockingStatus.docking'),
				actionRequired: actionRequiredToStop,
				IN_PROGRESS: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.detectingTheDock'),
				},
				SUCCESS: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.chargingDockDetected'),
					actionRequired: (
						<>
							{translationWithErrorHandling(intl, 'autoDockingStatus.press')}{' '}
							<img className="textIcon" src={chargingIcon} alt="" />{' '}
							<span className="textBold">
								{translationWithErrorHandling(intl, 'autoDockingStatus.toStopCharging')}
							</span>
						</>
					),
				},
				FAILED: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.chargingDockNotDetected'),
					icon: flashOff,
					actionRequired: (
						<>
							{translationWithErrorHandling(
								intl,
								'autoDockingStatus.driveCloserToTheChargingDockToParkTheRobot'
							)}
						</>
					),
				},
				icon: chargingIcon,
				iconDisplayRule: 'Minimized',
			},
			STARTING: {
				title: translationWithErrorHandling(intl, 'autoDockingStatus.starting'),
				icon: dockingStagePositioningIcon,
				IN_PROGRESS: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.parking'),
					icon: greenflash,
					actionRequired: actionRequiredToStop,
				},
				SUCCESS: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.parking'),
					icon: greenflash,
					actionRequired: actionRequiredToStop,
				},
				FAILED: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.notCharging'),
					icon: flashOff,
					actionRequired: failedActionRequired,
				},
			},
			DOCKING: {
				title: translationWithErrorHandling(intl, 'autoDockingStatus.docking'),
				icon: dockingStagePositioningIcon,
				STARTED: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.parking'),
					icon: greenflash,
					actionRequired: actionRequiredToStop,
				},
				IN_PROGRESS: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.parking'),
					icon: greenflash,
					actionRequired: actionRequiredToStop,
				},
				SUCCESS: {
					label: translationWithErrorHandling(intl, 'autoDockingStatus.parked'),
					icon: ParkedIcon,
					actionRequired: (
						<>{translationWithErrorHandling(intl, 'autoDockingStatus.awaitingCharging')}</>
					),
				},
				FAILED: {
					label: (() => {
						switch (customData) {
							case 'STOPPED':
								return translationWithErrorHandling(intl, 'autoDockingStatus.parkingStopped');
							case 'BLOCKED':
								return translationWithErrorHandling(intl, 'autoDockingStatus.parkingStopped');
							default:
								return translationWithErrorHandling(intl, 'autoDockingStatus.notCharging');
						}
					})(),
					icon: (() => {
						switch (customData) {
							case 'STOPPED':
								return greyFlash;
							case 'BLOCKED':
								return flashOff;
							default:
								return genericAlertIcon;
						}
					})(),
					actionRequired: (() => {
						switch (customData) {
							case 'STOPPED':
								return (
									<>
										{translationWithErrorHandling(intl, 'autoDockingStatus.theRobotWillNotCharge')}
									</>
								);
							case 'BLOCKED':
								return (
									<>
										{translationWithErrorHandling(
											intl,
											'autoDockingStatus.detectedAnObstacleNearTheRobotMakeSureTheRobotCanManeuverFreelyIn'
										)}
										{translationWithErrorHandling(intl, 'autoDockingStatus.frontOfTheChargingDock')}
									</>
								);
							default:
								return failedActionRequired;
						}
					})(),
				},
				RECOVERY: {
					label: (() => {
						switch (Number(customData)) {
							case 1:
								return 'One last try...';
							default:
								return 'Still parking...';
						}
					})(),
					icon: greenflash,
					actionRequired: actionRequiredToStop,
				},
			},
			STOPPING: null,
		};
		const config = DockingStatusConfig[stage];
		if (config === null) return config;

		return {
			title: config[state]?.title ?? config.title,
			label: config[state]?.label ?? config.label,
			icon: config[state]?.icon ?? config.icon,
			actionRequired: config[state]?.actionRequired ?? config.actionRequired,
		} as IConfig;
	}

	const [config, setConfig] = useState(getConfig(status, isAutoParking));
	const [timeoutId, setTimeoutId] = useState<ReturnType<typeof setTimeout>>();
	useEffect(() => {
		setConfig(getConfig(status, isAutoParking));
	}, [status, isAutoParking]);
	if (!timeoutId && status.stage === 'DOCKING' && status.state === 'SUCCESS')
		setTimeoutId(
			setTimeout(() => {
				setConfig({
					label: 'Charging',
					icon: chargingInProgressIcon,
					actionRequired: (
						<>
							{translationWithErrorHandling(intl, 'autoDockingStatus.batteryLevel')}{' '}
							{Math.floor(Number(robotStatus?.battery.level))}%
						</>
					),
				} as IConfig);
				setTimeoutId(undefined);
			}, 2000)
		);
	return !(status.stage === 'DETECTING' && status.state === 'FAILED' && !status.customData) &&
		config ? (
		<Snackbar
			title={config.label}
			icon={config.icon}
			iconDisplayRule={config.iconDisplayRule}
			actionRequired={config.actionRequired}
			iconWrapper={whiteCloseChevronIcon}
		/>
	) : null;
}

export default reduxConnector(AutoDockingStatusIndicator);
