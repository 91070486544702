import greenFlashIcon from 'images/green-flash2.svg';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { IActiveNavInput } from 'types';
import './index.scss';
import { translationWithErrorHandling } from '../../../../translation/handleMissingTranslation';

import { AppContext } from 'context/appContext';
import drivingDisabledIcon from 'images/driving-disabled.svg';
import drivingEnabledIcon from 'images/driving-enabled.svg';
import reconnectingIcon from 'images/sync-outline.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';

type Props = {
	isDrivingImpaired?: boolean;
	dockControllerStatus?: any;
	reconnecting: boolean;
};

const ActiveNavigationInput: React.FC<Props> = ({
	isDrivingImpaired,
	dockControllerStatus,
	reconnecting,
}) => {
	const intl = useIntl();
	const {
		navController: { activeNavInput, enabled: navControllerEnabled },
	} = useContext(AppContext);
	const [isMinimized, setIsMinimized] = useState(false);
	useEffect(() => {
		if (isDrivingImpaired && dockControllerStatus?.stage === 'DOCKING') {
			setIsMinimized(true);
		}
	}, [dockControllerStatus]);

	const DrivingDisabledIndicatorConfig: { icon: string; label: ReactNode } = {
		label: (
			<p className="indicator-label">
				{translationWithErrorHandling(intl, 'activeNavigationInput.drivingDisabled')}
			</p>
		),
		icon: drivingDisabledIcon,
	};

	const NavInputIndicatorsConfig: Record<
		IActiveNavInput,
		{ icon: string; label: React.ReactNode }
	> = {
		inputEnabled: {
			icon: drivingEnabledIcon,
			label: (
				<p className="indicator-label">
					{translationWithErrorHandling(intl, 'activeNavigationInput.drivingEnabled')}
				</p>
			),
		},
		'auto-docking': {
			icon: greenFlashIcon,
			label: (
				<div className="docking-wrapper">
					<p className="indicator-label">
						{translationWithErrorHandling(intl, 'activeNavigationInput.gobeParkEnabled')}
					</p>
					<div className="chip">
						<p className="chip-label">
							{translationWithErrorHandling(intl, 'activeNavigationInput.beta')}
						</p>
					</div>
				</div>
			),
		},
	};

	const indicatorConfig = reconnecting
		? {
				icon: reconnectingIcon,
				label: (
					<p className="indicator-label">
						{translationWithErrorHandling(intl, 'activeNavigationInput.reconnecting')}
					</p>
				),
		  }
		: activeNavInput === null || !navControllerEnabled
		? DrivingDisabledIndicatorConfig
		: NavInputIndicatorsConfig[activeNavInput];
	return isMinimized ? (
		<div className="container isMinimized" onClick={() => setIsMinimized(false)}>
			<img className={`navIcon ${reconnecting ? 'spin' : ''}`} src={indicatorConfig.icon} alt="" />
		</div>
	) : (
		<div className="container">
			<img className={`navIcon ${reconnecting ? 'spin' : ''}`} src={indicatorConfig.icon} alt="" />
			{indicatorConfig.label}
			<img
				className="closeIconWrapper"
				onClick={() => setIsMinimized(true)}
				src={whiteCloseChevronIcon}
				alt=""
			/>
		</div>
	);
};

export default ActiveNavigationInput;
