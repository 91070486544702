import React from 'react';
import { useIntl } from 'react-intl';
import './index.scss';
import { translationWithErrorHandling } from '../../../../translation/handleMissingTranslation';

import whiteWarningIcon from 'images/white-warning.svg';
import whiteChevronIcon from 'images/white-chevron.svg';
import refreshIcon from 'images/refresh.svg';

type Props = {
	isVisible: boolean;
	closeSession: () => void;
	onClickTryAgain: () => void;
};

export default function RobotUnavailableOverlay({
	onClickTryAgain,
	closeSession,
	isVisible,
}: Props) {
	const intl = useIntl();
	return isVisible ? (
		<div className="failedConnectionContainer">
			<img className="warningIconWrapper" alt="" src={whiteWarningIcon} />
			<div className="warningText">
				{translationWithErrorHandling(intl, 'failedInitPeerConnection.theRobotIsUnavailable')}
			</div>
			<div className="backAgainButtonsContainer">
				<div className="circleTextContainer">
					<div className="greenCircle blackCircle" onClick={() => closeSession()}>
						<img className="iconWrapper rotateNinety" src={whiteChevronIcon} alt="" />
					</div>
					<div>{translationWithErrorHandling(intl, 'failedInitPeerConnection.back')}</div>
				</div>
				<div className="circleTextContainer">
					<div className="whiteCircle blackCircle" onClick={() => onClickTryAgain()}>
						<img className="iconWrapper" src={refreshIcon} alt="" />
					</div>
					<div>{translationWithErrorHandling(intl, 'failedInitPeerConnection.tryAgain')}</div>
				</div>
			</div>
		</div>
	) : null;
}
