import { IntlShape } from 'react-intl';

export function translationWithErrorHandling(intl: IntlShape, translationId: string): string {
	const result = intl.formatMessage({ id: translationId });

	// If result equals the message ID, it means translation was missing
	if (result === translationId) {
		return intl.formatMessage({
			id: 'session.missingTranslation',
			defaultMessage: 'MISSING TRANSLATION',
		});
	}

	return result;
}
