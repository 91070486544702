import HelpCenterAnchor from 'components/helpCenterAnchor';
import Snackbar from 'components/snackbar';
import { Stats } from 'GoBeWebRTC/types';
import icon from 'images/red-warning.svg';
import whiteCloseChevronIcon from 'images/white-close-chevron.svg';
import { useMemo } from 'react';
import { useIntl } from 'react-intl';
import './index.scss';
import { translationWithErrorHandling } from '../../../../translation/handleMissingTranslation';

type Props = {
	stats: Stats;
};

export default function PeerConnectionStatusIndicator({ stats }: Props) {
	const intl = useIntl();
	const isVisible = useMemo<boolean>(
		() => stats?.succeededCandidatePair?.local?.relayProtocol === 'tls',
		[stats?.succeededCandidatePair?.local?.relayProtocol]
	);

	return isVisible ? (
		<Snackbar
			icon={icon}
			iconWrapper={whiteCloseChevronIcon}
			title="Network issue detected"
			actionRequired={
				<div className="peerConnectionStatusSnackbar">
					<span>
						{translationWithErrorHandling(intl, 'peerConnectionStatus.udpTrafficBlocked')}
					</span>
					<span>
						{translationWithErrorHandling(intl, 'peerConnectionStatus.forMoreInfoCheck')}{' '}
						<HelpCenterAnchor />
					</span>
				</div>
			}
		/>
	) : null;
}
