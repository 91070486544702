import backImage from 'images/back.svg';
import checkImage from 'images/check.svg';
import React, { useState, useEffect, useRef } from 'react';
import { useIntl } from 'react-intl';
import './index.scss';
import { translationWithErrorHandling } from '../../translation/handleMissingTranslation';

let settingsSelectInstances = 0;

export type SelectValue = {
	name: string;
	value: any;
};

interface Props {
	keyPrefix: string;
	selectedValue?: SelectValue;
	values: SelectValue[];
	onChange?: (data: SelectValue) => any;
}

const Select: React.FC<Props> = ({ keyPrefix, selectedValue, values, onChange }) => {
	const intl = useIntl();
	const [active, setActive] = useState<boolean>(false);
	const [activePmr, setActivePmr] = useState<SelectValue>(selectedValue!);

	const _handleClick = () => {
		setActive((value) => !value);
	};

	const _handleChange = (data: SelectValue) => () => {
		setActivePmr(data);
		_handleClick();
		onChange!(data);
	};

	const thisInstanceNumber = useRef(0);
	const idPrefix = `${keyPrefix}-${translationWithErrorHandling(intl, 'select.option')}-`;

	useEffect(() => {
		settingsSelectInstances++;
		thisInstanceNumber.current = settingsSelectInstances;
		const handleClickOutside = (event: MouseEvent) => {
			const target = event.target as HTMLElement;
			const selectContainers = document.querySelectorAll('.options-container');

			selectContainers.forEach((container) => {
				if (!target.closest(`#${'selectContainer' + thisInstanceNumber.current}`)) {
					setActive(false);
				}
			});
		};

		document.addEventListener('click', handleClickOutside);

		return () => {
			document.removeEventListener('click', handleClickOutside);
		};
	}, []);

	return (
		<div className="selectContainer" id={'selectContainer' + thisInstanceNumber.current}>
			<div className="select-box">
				<div className={`options-container ${active ? 'active' : ''}`}>
					{values.map((data, index) => (
						<div
							key={index}
							className={`${
								data.name === activePmr?.name ? 'option-container-disabled' : 'option-container'
							}`}
							onClick={() => {
								if (data.name !== activePmr?.name) {
									_handleChange(data)();
								}
							}}
						>
							<div className="option">
								<input
									type="radio"
									id={`${idPrefix}${data.value}`}
									name={`category-${thisInstanceNumber.current}`}
									value={data.value}
									onChange={_handleChange(data)}
									disabled={data.name === activePmr?.name}
								/>
								<label htmlFor={`${idPrefix}${data.value}`}>
									<div
										className={`${data.name === activePmr?.name ? 'option-label-disabled' : ''}`}
									>
										{data.name}
									</div>
									<div className="check">
										{data.name === activePmr?.name ? (
											<img src={checkImage} alt="check" width="15" />
										) : (
											''
										)}
									</div>
								</label>
							</div>
						</div>
					))}
				</div>
				<div className="wrapper-select">
					<div className="selected" onClick={_handleClick}>
						<div>{activePmr ? activePmr.name : 'Select'}</div>
						<img src={backImage} width="15" alt="arrow-down" />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Select;
