import React from 'react';
import { useIntl } from 'react-intl';
import './index.scss';
import { translationWithErrorHandling } from '../../../../translation/handleMissingTranslation';

import cloudOfflineIcon from 'images/cloud-offline.svg';
import { ReactComponent as CloseIcon } from 'images/close.svg';

type Props = {
	isVisible: boolean;
	closeSession: () => void;
	robotName: string;
};

export default function SessionNetworkFailureOverlay({
	isVisible,
	closeSession: backClick,
	robotName,
}: Props) {
	const intl = useIntl();
	return isVisible ? (
		<div className="failedConnectionContainer">
			<img className="warningIconWrapper" alt="" src={cloudOfflineIcon} />
			<div className="warningText">
				{translationWithErrorHandling(intl, 'failedSeesionPeerConnection.yourSessionOn')}{' '}
				{robotName}{' '}
				{translationWithErrorHandling(
					intl,
					'failedSeesionPeerConnection.wasLostDueToNetworkProblems'
				)}
			</div>
			<div className="failedAgainButtonsContainer">
				<div className="circleTextContainer">
					<div className="redCircle blackCircle" onClick={() => backClick()}>
						<div className="iconWrapper rotateNinety">
							<CloseIcon />
						</div>
					</div>
					<div>{translationWithErrorHandling(intl, 'failedSeesionPeerConnection.end')}</div>
				</div>
			</div>
		</div>
	) : null;
}
