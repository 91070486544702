import React from 'react';
import { useIntl } from 'react-intl';
import './index.scss';
import { LocalSessionInfo } from 'types';
import { translationWithErrorHandling } from '../../../../translation/handleMissingTranslation';

type Props = {
	isVisible: boolean;
	isSessionStarting: boolean;
	isSessionInitializing: boolean;
	robot: LocalSessionInfo['robot'];
	onClickEndSession: () => void;
};

export default function RetryingSessionOverlay({
	onClickEndSession,
	isVisible,
	isSessionStarting,
	isSessionInitializing,
	robot,
}: Props) {
	const intl = useIntl();
	return isVisible ? (
		<div
			className={`retrying-session-container ${
				isSessionInitializing ? 'retrying-session-container-initializing' : ''
			}`}
		>
			{/* <div className="loading-indicator" /> */}

			<div className="title">
				{translationWithErrorHandling(intl, 'retryingSession.reconnecting')}
			</div>
			<div className={`message ${!isSessionInitializing && isSessionStarting ? 'hidden' : ''}`}>
				{translationWithErrorHandling(
					intl,
					'retryingSession.yourSessionWillBeAutomaticallyResumed'
				)}
			</div>
			<div className="end-session-button" onClick={() => onClickEndSession()}>
				{translationWithErrorHandling(intl, 'retryingSession.endSession')}
			</div>
		</div>
	) : null;
}
