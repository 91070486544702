import { AppContext } from 'context/appContext';
import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { getEnvFromURL } from 'utils/environment';
import WebRTCSession from 'webRTC';
import { IceTransportProtocol, LocalMedia, RemoteMedia } from 'webRTC/types';
import { useStats } from './hooks/useStats';
import { getStats } from './internals';
import {
	CloudLogLevel,
	DataChannelMessageType,
	DataChannels,
	GobeWebRTCSessionConfiguration,
	LOCAL_MEDIA_CONSTRAINTS,
	PeerConnectionEndReasonCode,
	PilotPrimaryCamera,
	PivotState,
	PrimaryCameraState,
	RobotNavCamera,
	RobotPrimaryCamera,
	RobotStatus,
	ConnectionsQuality,
	SOFT_RETRIES,
	SOFT_RETRY_DELAY_MS,
	SessionState,
	Stats,
} from './types';
import { arrayToKeyValueDict, localTracksLabelMap, remoteTracksMidsMap } from './utils';

import {
	SettingHeaders,
	SettingPageHeaders,
	SettingPageSectionHeaders,
	SettingSectionHeaders,
	SettingTabHeaders,
} from 'hooks/useSettingsController';

let sendWebrtcStatsReportAdded = false;

/**
 * GoBeWebRTC factory.
 *
 * Returns a GoBe web rtc session.
 */
export default function useGoBeWebRTC(configuration: GobeWebRTCSessionConfiguration): {
	pilotMedia: { [trackKey: string]: LocalMedia };
	robotMedia: { [trackKey: string]: RemoteMedia };
	dataChannels: { [label: string]: RTCDataChannel };
	robotStatus: RobotStatus | undefined;
	connectionsQuality: ConnectionsQuality;
	sessionState: SessionState;
	isSessionPaused: boolean;
	primaryCameraState: PrimaryCameraState;
	pivotState: PivotState;
	toggleSuperZoom: () => void;
	togglePause: () => void;
	pivot: (degrees: number) => void;
	setLocalDisplays: (arg: {}) => void;
	shareUrl: (arg: string) => boolean;
	reportWebRTCEvent: (data: any) => void;
	reportWebRTCUserRating: (rating: number, message: string | null) => void;
	start: () => void;
	end: (reason?: PeerConnectionEndReasonCode, callback?: () => void) => Promise<void>;
	isStreamSynced: boolean;
	stats: Stats;
} {
	const webRTCSessionConfiguration = useRef(configuration.webRTCSessionConfiguration).current;
	const [pilotMedia, setPilotMedia] = useState<{ [trackKey: string]: LocalMedia }>({});
	const [robotMedia, setRobotMedia] = useState<{ [trackKey: string]: RemoteMedia }>({});
	const [dataChannels, setDataChannels] = useState<{ [label: string]: RTCDataChannel }>({});
	const refDataChannels = useRef(dataChannels);
	const [controlDataChannelOpen, setControlDataChannelOpen] = useState<boolean>(false);
	const [robotStatus, setRobotStatus] = useState<RobotStatus | undefined>(
		configuration.robotStatus
	);
	const [connectionsQuality, setConnectionsQuality] = useState<ConnectionsQuality>({
		1: {
			dbm: 0,
			quality: 0,
		},
		2: {
			dbm: 0,
			quality: 0,
		},
	});
	const gobeWebRTCRef = useRef<WebRTCSession>();
	const [sessionState, setSessionState] = useState<SessionState>('NotInitialized');
	const isSessionPaused = useRef<boolean>(false);
	const [softRetryIntervalId, setSoftRetryIntervalId] = useState<ReturnType<typeof setInterval>>();
	const [primaryCameraState, setPrimaryCameraState] = useState<PrimaryCameraState>({
		currentPrimaryCamera: RobotPrimaryCamera.WIDE_CAM,
		isChangingPrimaryCameraTo: null,
	});
	const [pivotState, setPivotState] = useState<PivotState>({
		currentPivot: null,
		lastPivotResult: null,
	});
	const [isStreamSynced, setIsStreamSynced] = useState<boolean>(!configuration.syncStreamDisplay);
	const { feedbackController, settingsController } = useContext(AppContext);

	const reportWebRTCEvent: (data: any) => void = ({ type, name, parameters }) =>
		gobeWebRTCRef.current?.watchRTC?.addEvent({
			type,
			name,
			parameters,
		});

	const reportWebRTCUserRating: (rating: number, message: string | null) => void = (
		rating,
		message
	) => {
		gobeWebRTCRef.current?.watchRTC?.setUserRating(rating, message);
	};

	const { stats, captureGoBeSwitchStats, addStableFramesCallback } = useStats(
		gobeWebRTCRef.current?.peerConnection!,
		{
			robot: webRTCSessionConfiguration.clientId!,
			environment: getEnvFromURL(),
			sessionType: configuration.sessionType,
			sessionId: webRTCSessionConfiguration.id!,
		},
		(stat: any) => {
			reportWebRTCEvent({
				type: 'global',
				name: 'switchingStat',
				parameters: {
					stat,
				},
			});
		}
	);
	const iceRestartingRef = useRef<boolean>(false);

	// Report robot status
	const robotStatusRef = useRef<typeof robotStatus>(robotStatus);
	useMemo(() => {
		robotStatusRef.current = robotStatus;
		return robotStatus;
	}, [robotStatus]);
	const reportRobotStatus = () => {
		reportWebRTCEvent({
			type: 'global',
			name: 'robotStatus',
			parameters: {
				robotStatus: robotStatusRef.current,
			},
		});
	};
	const clearSoftRetryInterval = () => {
		console.log('CLEARING SOFT RETRY INTERVAL');
		clearInterval(softRetryIntervalId!);
		setSoftRetryIntervalId(undefined);
	};
	const toggleSuperZoom: () => void = () => {
		if (!gobeWebRTCRef.current) return;
		const isChangingPrimaryCameraTo =
			primaryCameraState?.currentPrimaryCamera === RobotPrimaryCamera.WIDE_CAM
				? RobotPrimaryCamera.ZOOM_CAM
				: RobotPrimaryCamera.WIDE_CAM;
		reportWebRTCEvent({
			type: 'global',
			name: 'switchingCamera',
			parameters: {
				toCameraType: isChangingPrimaryCameraTo,
				currentCameraType: primaryCameraState?.currentPrimaryCamera,
			},
		});
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			JSON.stringify({
				type: DataChannelMessageType.REQUEST_CAMERA_SWITCH,
				data: {
					to: isChangingPrimaryCameraTo,
				},
			})
		);
		setPrimaryCameraState({
			...primaryCameraState!,
			isChangingPrimaryCameraTo,
		});
	};
	const togglePause: () => void = () => {
		if (!gobeWebRTCRef.current) return;
		let streams: any = [];
		gobeWebRTCRef.current?.localMedia.forEach((localMedia) => {
			if (!streams.find((stream: any) => stream.id === localMedia.media?.stream.id))
				streams.push(localMedia.media?.stream);
		});
		streams.forEach((stream: MediaStream) =>
			stream.getTracks()?.forEach((track: MediaStreamTrack) => {
				track.enabled = !track.enabled;
			})
		);
		gobeWebRTCRef.current?.remoteMedia.forEach(
			(remoteMedia) => (remoteMedia.track.enabled = !remoteMedia.track.enabled)
		);
		const isPaused = sessionState === 'Paused';
		reportWebRTCEvent({
			type: 'global',
			name: isPaused ? 'sessionUnpaused' : 'sessionPaused',
			parameters: {},
		});
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			JSON.stringify({
				type: isPaused ? DataChannelMessageType.RESUME : DataChannelMessageType.PAUSE,
			})
		);
		isSessionPaused.current = !isPaused;
		setSessionState(isPaused ? 'InProgress' : 'Paused');
	};
	const pivot: (degrees: number) => void = (degrees) => {
		if (!gobeWebRTCRef.current) return;

		if (dataChannels[DataChannels.NAV_DATACHANNEL]?.readyState !== 'open') {
			console.debug(
				`abort pivot() -> datachannel.readyState '${
					dataChannels[DataChannels.NAV_DATACHANNEL]?.readyState
				}'`
			);
			return;
		}

		if (!degrees) {
			console.debug('abort pivot() -> degrees is 0');
			return;
		}

		const currentPivot = {
			id: Math.floor(Math.random() * 100),
			degrees,
		};

		try {
			dataChannels[DataChannels.NAV_DATACHANNEL].send(
				JSON.stringify({
					type: DataChannelMessageType.PIVOT,
					data: {
						...currentPivot,
						operation: 'request',
					},
				})
			);
		} catch (error) {
			console.error('Failed to send PIVOT command to remote peer', error);
		}

		setPivotState((prev) => ({
			...prev,
			currentPivot,
			lastPivotResult: null,
		}));

		reportWebRTCEvent({
			type: 'global',
			name: 'pivotRequest',
			parameters: pivotState,
		});
	};
	const setLocalDisplays: (arg: {}) => void = (arg) => {
		if (!gobeWebRTCRef.current) return;

		if (
			dataChannels[DataChannels.CONTROL_DATACHANNEL] &&
			dataChannels[DataChannels.CONTROL_DATACHANNEL].readyState === 'open'
		) {
			dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
				JSON.stringify({
					type: DataChannelMessageType.SET_LOCAL_DISPLAYS,
					data: arg,
				})
			);
		} else {
			console.warn(
				'Control data channel is not open, ready state: ',
				dataChannels[DataChannels.CONTROL_DATACHANNEL]?.readyState
			);
		}
	};
	const shareUrl: (arg: string) => boolean = (arg) => {
		if (!gobeWebRTCRef.current) return false;
		if (
			!dataChannels[DataChannels.CONTROL_DATACHANNEL] ||
			dataChannels[DataChannels.CONTROL_DATACHANNEL]?.readyState !== 'open'
		) {
			console.warn('Control data channel is not open yet');
			return false;
		}
		console.log('shareUrl', arg);
		dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
			JSON.stringify({
				type: DataChannelMessageType.SHARE_URL,
				data: {
					url: arg,
				},
			})
		);
		return true;
	};
	const sendWebrtcStatsReport: (arg: any) => void = (arg) => {
		if (!gobeWebRTCRef.current) return;
		if (!refDataChannels.current[DataChannels.STATUS_DATACHANNEL]) {
			console.error('Something went wrong, not sending webrtc stats report');
			return;
		}
		if (refDataChannels.current[DataChannels.STATUS_DATACHANNEL].readyState !== 'open') {
			console.error('Status datachannel is not open yet');
			return;
		}
		refDataChannels.current[DataChannels.STATUS_DATACHANNEL].send(
			JSON.stringify({
				type: DataChannelMessageType.WEBRTC_STATS_REPORT,
				data: arg,
			})
		);
	};
	// Refresh the webcam source when the initial connection is complete.
	// This ensures that webrtc negotiation is complete before we apply settings
	const isInitialConnectionComplete = useRef<boolean>(false);

	const start: () => void = () => {
		setPilotMedia({});
		setRobotMedia({});
		setDataChannels({});
		setControlDataChannelOpen(false);
		setIsStreamSynced(!configuration.syncStreamDisplay);
		const webRTCSession = new WebRTCSession({
			...webRTCSessionConfiguration,
			localMediaConstraints: LOCAL_MEDIA_CONSTRAINTS as MediaStreamConstraints,
			ondatachannel: [],
			dataChannels: [
				{
					label: DataChannels.NAV_DATACHANNEL,
					datachannelDict: {
						ordered: false,
						maxRetransmits: 0,
						maxPacketLifeTime: undefined,
					} as RTCDataChannelInit,
					onmessage: (e) => {
						try {
							const { type, data } = JSON.parse(e.data);
							if (!type) {
								console.debug('Received invalid message from nav-datachannel', e);
								return;
							}

							console.log(
								'RECEIVED MESSAGE FROM DATACHANNEL nav-datachannel',
								DataChannels.NAV_DATACHANNEL,
								e.data
							);

							switch (type) {
								case DataChannelMessageType.PIVOT:
									setPivotState((prev) => ({
										...prev,
										currentPivot: null,
										lastPivotResult: data,
									}));

									reportWebRTCEvent({
										type: 'global',
										name: data.success ? 'pivotSuccess' : 'pivotError',
										parameters: {
											data,
										},
									});
									break;
							}
						} catch (error) {
							// Nav messages logs frequently
							// console.error('Cannot process nav message received', error, 'Message', e);
						}
					},
				},
				{
					label: DataChannels.CONTROL_DATACHANNEL,
					onmessage: (e) => {
						try {
							const { type, data } = JSON.parse(e.data);
							if (!type) {
								console.debug('Received invalid message from control-datachannel', e);
								return;
							}

							console.log(
								'RECEIVED MESSAGE FROM DATACHANNEL control-datachannel',
								DataChannels.CONTROL_DATACHANNEL,
								e.data
							);

							switch (type) {
								case DataChannelMessageType.CAMERA_SWITCH_RESPONSE:
									if (!Object.values(RobotPrimaryCamera).includes(data.currentPrimaryCamera)) {
										console.debug('Received invalid message data from control-datachannel', e);
										return;
									}

									setPrimaryCameraState((prev) => ({
										...prev,
										currentPrimaryCamera: data.currentPrimaryCamera,
										isChangingPrimaryCameraTo: null,
									}));

									reportWebRTCEvent({
										type: 'global',
										name: data.success ? 'switchCameraSuccess' : 'switchCameraError',
										parameters: {
											currentCameraType: primaryCameraState.currentPrimaryCamera,
										},
									});
									break;
								case DataChannelMessageType.SHOW_STREAM:
									if (!isStreamSynced) {
										setIsStreamSynced(true);
										if (!isSessionPaused.current) {
											// Enable local audio tracks
											Object.values(pilotMedia).forEach((v) =>
												v.media?.stream.getAudioTracks().forEach((track) => (track.enabled = true))
											);
											reportWebRTCEvent({
												type: 'global',
												name: 'receivedShowStream',
												parameters: {},
											});
										}
									}
									break;
								case DataChannelMessageType.STAIRS_DETECTED:
									console.log('STAIRS DETECTED');
									break;
							}
						} catch (error) {
							console.error('Cannot process status message received', error, 'Message', e);
						}
					},
					onopen: (e) => {
						setControlDataChannelOpen(true);
					},
				},
				{
					label: DataChannels.STATUS_DATACHANNEL,
					onmessage: (e) => {
						console.debug(
							'RECEIVED MESSAGE FROM DATACHANNEL',
							DataChannels.STATUS_DATACHANNEL,
							e.data
						);
						try {
							const { type, data } = JSON.parse(e.data);
							switch (type) {
								case DataChannelMessageType.ROBOT_STATUS:
									setRobotStatus(data);
									break;
								case DataChannelMessageType.CONNECTIONS_QUALITY:
									setConnectionsQuality(data);
									break;
							}
						} catch (error) {
							console.error('Cannot process status message received', error, 'Message', e);
						}
					},
				},
			],
			onLocalMediaError: (error) => {
				console.error('LOCAL MEDIA ERROR', error);
				close(error.message);
			},
			onSDPOfferExtensions: {
				gobeSwitching: async (sdp?: string) => {
					// Roaming handling
					console.log('RECEIVED GOBE SWITCHING - ROAMING STARTED');
					reportRobotStatus();
					reportWebRTCEvent({
						type: 'global',
						name: 'gobeSwitching',
						parameters: {
							sdp,
						},
					});
					webRTCSession?.iceRestart();
					iceRestartingRef.current = true;
					captureGoBeSwitchStats();
				},
			},
			onSDPAnswerExtensions: {
				remoteReadyForRetry: async () => {
					// Robot is ready for retry
					console.log('RECEIVED READY FOR RETRY - RESTARTING SESSION');
					await close();
					start();
				},
			},
			onOpenConnectionListeners: {
				connectionstatechange: (state) => {
					console.log('CONNECTION STATE CHANGED: ', state);
					if (state === 'connected') {
						isInitialConnectionComplete.current = true;
					}
				},
				iceconnectionstatechange: (state) => {
					console.log('ICE CONNECTION STATE CHANGED: ', state);
					switch (state) {
						case 'connected':
							setSessionState(isSessionPaused.current ? 'Paused' : 'InProgress');
							break;
						case 'disconnected':
							setSessionState('SoftRetrying');
							break;
						case 'failed':
							setSessionState('Retrying');
							break;
					}
				},
				icegatheringstatechange: (state) => {
					switch (state) {
						case 'complete':
							if (iceRestartingRef.current) {
								// Prompt GoBe switch feedback as a stable frame callback
								addStableFramesCallback(() =>
									feedbackController.prompt('GoBeSwitch', (rating, review) => {
										reportWebRTCEvent({
											type: 'global',
											name: 'switchingFeedback',
											parameters: {
												rating,
												review,
											},
										});
									})
								);
								iceRestartingRef.current = false;
							}
							break;
					}
					console.log('ICE GATHERING STATE CHANGED: ', state);
				},
				signalingstatechange: (state) => {
					console.log('SIGNALING STATE CHANGED: ', state);
				},
				negotiationneeded: () => {
					console.log('NEGOTIATION NEEDED');
				},
				icecandidateerror: (ev) => {
					console.log('ICE CANDIDATE ERROR: ', ev);
				},
			},
			watchRTC: {
				rtcApiKey: '2610b825-e4f2-4ab0-8b27-44820805465a',
				rtcRoomId: webRTCSessionConfiguration.id,
				rtcPeerId: webRTCSessionConfiguration.clientId,
				keys: {
					pilotId: webRTCSessionConfiguration.pilotId,
					robotId: webRTCSessionConfiguration.clientId,
					env: getEnvFromURL(),
					sessionType: configuration.sessionType,
				},
				...(configuration.cloudLogLevel
					? { console: { level: configuration.cloudLogLevel, override: true } }
					: {}),
			},
		});

		webRTCSession.setLocalMedia = (value: LocalMedia[]) => {
			if (isSessionPaused.current)
				// Disable tracks
				Object.values(value).forEach((v) =>
					v.media?.stream?.getTracks().forEach((track) => (track.enabled = false))
				);
			else if (!isStreamSynced)
				// Disable audio tracks
				Object.values(value).forEach((v) =>
					v.media?.stream?.getAudioTracks().forEach((track) => (track.enabled = false))
				);

			Object.values(value).forEach((v) =>
				v.media?.stream?.getTracks().forEach((track) => {
					track.onended = async () => {
						if (!gobeWebRTCRef.current) return;

						if (track.kind === 'video') {
							navigator.mediaDevices
								.getUserMedia({
									video: LOCAL_MEDIA_CONSTRAINTS.video,
								})
								.then((camera) => {
									settingsController.setSettingValue(SettingHeaders.CAMERA, camera);
								})
								.catch((error) => {
									console.error('Error getting video stream: ', error);
									alert('Error getting video stream: ' + error);
								});
						} else if (track.kind === 'audio') {
							navigator.mediaDevices
								.getUserMedia({
									audio: LOCAL_MEDIA_CONSTRAINTS.audio,
								})
								.then((microphone) => {
									settingsController.setSettingValue(SettingHeaders.MICROPHONE, microphone);
								})
								.catch((error) => {
									console.error('Error getting audio stream: ', error);
									alert('Error getting audio stream: ' + error);
								});
						}
					};
				})
			);

			webRTCSession.localMedia = value;
			// Map local media to pilot media
			setPilotMedia(
				value
					? arrayToKeyValueDict(
							value,
							(localMedia) => localMedia?.media?.label!,
							localTracksLabelMap
					  )
					: {}
			);
		};
		webRTCSession.setRemoteMedia = (value: RemoteMedia[]) => {
			if (isSessionPaused.current)
				// Disable tracks
				Object.values(value).forEach((v) =>
					v.streams.forEach((stream) =>
						stream.getTracks().forEach((track) => (track.enabled = false))
					)
				);
			webRTCSession.remoteMedia = value;
			const newRobotMedia = arrayToKeyValueDict(
				value,
				(media) => media?.transceiver?.mid!,
				remoteTracksMidsMap
			);
			// Map remote media to robot media
			setRobotMedia(value ? newRobotMedia : {});
			Object.entries(newRobotMedia).forEach(([label, media]) => {
				webRTCSession.watchRTC.mapTrack(media.track.id!, label);
			});
		};
		webRTCSession.setDataChannels = (value: RTCDataChannel[]) => {
			webRTCSession.dataChannels = value;
			// Map data channels to key-labelled data channels
			setDataChannels(value ? arrayToKeyValueDict(value, (channel) => channel?.label!) : {});
		};
		webRTCSession?.watchRTC.addStateListener(() => reportRobotStatus());

		// TODO: Filter to leave only automation functions
		(window as any).getWebRTCStats = () => getStats(webRTCSession.peerConnection);
		(window as any).iceRestart = () => webRTCSession.iceRestart();
		(window as any).webRTCSession = webRTCSession;
		(window as any).setSessionState = (state: SessionState) => setSessionState(state);
		(window as any).enableCloudLog = (level: CloudLogLevel = 'log') =>
			webRTCSession.watchRTC.setConfig({
				...webRTCSession.watchRTC.configuration,
				console: { level, override: true },
			});
		(window as any).setIceTransportProtocol = (protocol: IceTransportProtocol) => {
			webRTCSession.configuration.iceTransportProtocol = protocol;
			webRTCSession.iceRestart();
		};

		gobeWebRTCRef.current = webRTCSession;
		setSoftRetryIntervalId(undefined);
		setPrimaryCameraState({
			currentPrimaryCamera: RobotPrimaryCamera.WIDE_CAM,
			isChangingPrimaryCameraTo: null,
		});
		webRTCSession?.open();

		if (!sendWebrtcStatsReportAdded) {
			(window as any).addEventListener('sendWebrtcStatsReport', (event: any) => {
				sendWebrtcStatsReport(event.detail as any);
			});
			sendWebrtcStatsReportAdded = true;
		}
	};
	const restart: () => void = () => {
		gobeWebRTCRef.current?.signalingClient.sendSDPOffer({
			sdp: '',
			type: 'readyForRetry',
			toJSON: function () {
				return { sdp: this.sdp, type: this.type };
			},
		} as any);
		reportWebRTCEvent({
			type: 'global',
			name: 'hardRetry',
			parameters: {},
		});
	};
	const close: (reason?: string | undefined) => Promise<void> = async (reason) => {
		reportRobotStatus();

		if (reason === 'USER_EARLY_CLOSE') {
			// Tell robot to close connection now
			gobeWebRTCRef.current?.signalingClient.sendSDPOffer({
				sdp: '',
				type: 'closeConnection',
				toJSON: function () {
					return { sdp: this.sdp, type: this.type };
				},
			} as any);
		}

		gobeWebRTCRef.current?.close(reason);

		// Wait for the closing event to be received
		return new Promise((r) => setTimeout(r, 500));
	};
	const end: (
		reason?: PeerConnectionEndReasonCode,
		callback?: () => void
	) => Promise<void> = async (reason = 'LOCAL_HANGUP', callback) => {
		if (!gobeWebRTCRef.current) return;
		setSessionState('Ended');
		try {
			dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
				JSON.stringify({
					type: DataChannelMessageType.HANG_UP,
					data: reason,
				})
			);
		} catch (e) {
			console.error(e);
		}
		try {
			await close(reason);
		} catch (e) {
			console.error(e);
		}
		if (callback) callback();
	};
	// Close when unmounting
	useEffect(() => {
		return () => {
			close();
		};
	}, []);

	useEffect(() => {
		refDataChannels.current = dataChannels;
	}, [dataChannels]);

	useEffect(() => {
		const showStreamSent = !(
			!isStreamSynced &&
			pilotMedia[PilotPrimaryCamera.LOCAL]?.media?.stream?.active &&
			robotMedia[RobotPrimaryCamera.WIDE_CAM]?.streams[0]?.active &&
			robotMedia[RobotNavCamera.NAV_CAM]?.streams[0]?.active &&
			controlDataChannelOpen
		);
		if (!showStreamSent) {
			console.log(
				'Sending show stream',
				showStreamSent,
				isStreamSynced,
				pilotMedia[PilotPrimaryCamera.LOCAL]?.media?.stream?.active,
				robotMedia[RobotPrimaryCamera.WIDE_CAM]?.streams[0]?.active,
				robotMedia[RobotNavCamera.NAV_CAM]?.streams[0]?.active,
				controlDataChannelOpen
			);
			setTimeout(() => {
				dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
					JSON.stringify({
						type: 'show_stream',
					})
				);
				reportWebRTCEvent({
					type: 'global',
					name: 'sendShowStream',
					parameters: {},
				});
			}, 1000);
		}

		(window as any).pilotAllowMovement = () => {
			dataChannels[DataChannels.CONTROL_DATACHANNEL].send(
				JSON.stringify({
					type: DataChannelMessageType.PILOT_ALLOW_MOVEMENT,
				})
			);
			console.log('Allowing movement despite stairs detected');
		};
	}, [dataChannels, isStreamSynced, pilotMedia, robotMedia, controlDataChannelOpen]);

	useEffect(() => {
		switch (sessionState) {
			case 'InProgress':
				clearSoftRetryInterval();
				break;
			case 'SoftRetrying':
				if (!softRetryIntervalId) {
					let retries = SOFT_RETRIES;
					console.log('STARTED SOFT RETRY', retries);
					setSoftRetryIntervalId(
						setInterval(() => {
							console.debug('Soft retrying', retries);
							if (retries === 0) {
								setSessionState('Retrying');
							} else if (sessionState === 'SoftRetrying') gobeWebRTCRef.current?.iceRestart();
							retries--;
						}, SOFT_RETRY_DELAY_MS)
					);
				}
				break;
			case 'Retrying':
				console.log('STARTED HARD RETRY');
				clearSoftRetryInterval();
				restart();
				break;
		}
		return () => {
			clearTimeout(softRetryIntervalId!);
			setSoftRetryIntervalId(undefined);
		};
	}, [sessionState]);

	// TODO: Filter to leave only automation functions
	(window as any).getSessionState = () => sessionState;
	(window as any).start = start;
	(window as any).end = end;
	(window as any).forceZoomToggle = () => {
		setPrimaryCameraState({
			currentPrimaryCamera: primaryCameraState.isChangingPrimaryCameraTo!,
			isChangingPrimaryCameraTo: null,
		});
		setPivotState({
			currentPivot: null,
			lastPivotResult: null,
		});
	};
	(window as any).setRobotStatus = () => {
		setRobotStatus(
			(value: RobotStatus | undefined) =>
				({
					...(value ?? {}),
					interface: {
						...value?.interface,
						interface_id: '3',
					},
				} as RobotStatus)
		);
	};

	useEffect(() => {
		const useMotionEncoders =
			settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
				SettingPageHeaders.TOOLS
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.MOTION_ENCODERS
			];

		gobeWebRTCRef.current
			?.getLocalMedia()
			.then(() => {
				const localMedia = gobeWebRTCRef.current?.localMedia[0]?.media?.stream;
				const peerConnection = gobeWebRTCRef.current?.peerConnection;

				if (!localMedia?.getTracks() || !peerConnection) {
					return;
				}

				const webRTCSenders = peerConnection.getSenders();
				if (!webRTCSenders?.length) {
					return;
				}

				webRTCSenders.forEach((webRTCSender: RTCRtpSender) => {
					if (webRTCSender?.track?.kind === 'video') {
						const contentHint = useMotionEncoders.value ? 'motion' : 'detail';
						const degradationPref = useMotionEncoders.value
							? 'maintain-framerate'
							: 'maintain-resolution';

						console.log('Using content hint:', contentHint);
						console.log('With degradationPreference:', degradationPref);

						webRTCSender.track.contentHint = contentHint;

						try {
							const params = webRTCSender.getParameters();
							webRTCSender.setParameters({
								...params,
								degradationPreference: degradationPref,
							});
						} catch (error) {
							//Do nothing
						}
					}
				});
			})
			.catch((error) => {
				//Do nothing
			});

		const useBitrateToRequest =
			settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
				SettingPageHeaders.TOOLS
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.BITRATE_TO_REQUEST
			];

		if (
			(window as any).webRTCSession &&
			(window as any).webRTCSession.dataChannels[1] &&
			(window as any).webRTCSession.dataChannels[1].readyState === 'open'
		) {
			console.log('With target bitrate preset: "', useBitrateToRequest.value, '"');
			(window as any).webRTCSession.dataChannels[1].send(
				JSON.stringify({
					type: 'request_bitrate_change',
					data: {
						target_bitrate_preset: useBitrateToRequest.value,
					},
				})
			);
		}
	}, [settingsController.settings]);

	useEffect(() => {
		if (!gobeWebRTCRef.current) return;
		const camera =
			settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
				SettingPageHeaders.AUDIOVIDEO
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.AUDIOVIDEO].children[
				SettingHeaders.CAMERA
			].value;
		const microphone =
			settingsController.settings[SettingPageSectionHeaders.APP_SETTINGS].children[
				SettingPageHeaders.AUDIOVIDEO
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.AUDIOVIDEO].children[
				SettingHeaders.MICROPHONE
			].value;
		const useMotionEncoders =
			settingsController.settings[SettingPageSectionHeaders.ADMIN].children[
				SettingPageHeaders.TOOLS
			].children[SettingTabHeaders.GENERAL].children[SettingSectionHeaders.MISC].children[
				SettingHeaders.MOTION_ENCODERS
			];

		let shouldUpdateLocalMedia = false;
		if (
			gobeWebRTCRef.current.configuration.preferredDevices?.camera &&
			(!gobeWebRTCRef.current.configuration.preferredDevices.camera?.name ||
				gobeWebRTCRef.current.configuration.preferredDevices.camera.name != camera.name)
		) {
			gobeWebRTCRef.current.configuration.preferredDevices.camera.name = camera.name;
			shouldUpdateLocalMedia = true;
		}
		if (
			gobeWebRTCRef.current.configuration.preferredDevices?.microphone &&
			(!gobeWebRTCRef.current.configuration.preferredDevices.microphone?.name ||
				gobeWebRTCRef.current.configuration.preferredDevices.microphone.name != microphone.name)
		) {
			gobeWebRTCRef.current.configuration.preferredDevices.microphone.name = microphone.name;
			shouldUpdateLocalMedia = true;
		}
		if (shouldUpdateLocalMedia) {
			let shouldUpdateWebRTCMedia = false;
			//Stop current local media stream and track
			if (gobeWebRTCRef.current?.localMedia[0].media?.stream.getTracks()) {
				const localMediaStream: MediaStream = gobeWebRTCRef.current?.localMedia[0].media?.stream;
				localMediaStream.getTracks().forEach((mediaStreamTrack) => {
					if (
						(mediaStreamTrack.kind == 'audio' &&
							mediaStreamTrack.getSettings().deviceId != microphone.value) ||
						(mediaStreamTrack.kind == 'video' &&
							mediaStreamTrack.getSettings().deviceId != camera.value)
					) {
						mediaStreamTrack.stop();
						localMediaStream.removeTrack(mediaStreamTrack);
						shouldUpdateWebRTCMedia = true;
					}
				});
			} else {
				console.error('No active track found to be stopped');
			}

			// Update local media with new stream
			if (shouldUpdateWebRTCMedia) {
				gobeWebRTCRef.current?.getLocalMedia().then(() => {
					if (gobeWebRTCRef.current?.localMedia[0].media?.stream.getTracks()) {
						const localMediaStreamTracks: MediaStreamTrack[] =
							gobeWebRTCRef.current?.localMedia[0].media?.stream.getTracks();
						const webRTCSenders: RTCRtpSender[] =
							gobeWebRTCRef.current?.peerConnection.getSenders();
						if (webRTCSenders?.length) {
							webRTCSenders.forEach((webRTCSender: RTCRtpSender) => {
								if (webRTCSender.track) {
									const updatedTrack: MediaStreamTrack | undefined = localMediaStreamTracks.find(
										(track) =>
											track.kind === webRTCSender.track?.kind && track.id !== webRTCSender.track?.id
									);
									if (updatedTrack) {
										// Update media sent to remote peer
										webRTCSender.replaceTrack(updatedTrack);

										// Apply content hint again
										if (webRTCSender.track.kind === 'video') {
											console.log(
												'Using content hint: "' + useMotionEncoders.value
													? 'motion'
													: 'detail' + '"'
											);
											console.log(
												'With degradationPreference: "' + useMotionEncoders.value
													? 'framerate'
													: 'resolution' + '"'
											);

											webRTCSender.track.contentHint = useMotionEncoders.value
												? 'motion'
												: 'detail';
											const params = webRTCSender.getParameters();
											let newParams = {
												...params,
												degradationPreference: useMotionEncoders.value
													? 'maintain-framerate'
													: 'maintain-resolution',
											} as any;
											webRTCSender.setParameters(newParams);
										}
									} else {
										console.error('No track found to be updated');
									}
								} else {
									console.error('No track found in webRTCSender');
								}
							});
						} else {
							console.error('No senders found in webRTC connection');
						}
					}
				});
			}
		}
	}, [settingsController.settings, isInitialConnectionComplete.current]);

	return {
		pilotMedia,
		robotMedia,
		connectionsQuality,
		dataChannels,
		robotStatus,
		sessionState,
		isSessionPaused: isSessionPaused.current,
		primaryCameraState,
		pivotState,
		toggleSuperZoom,
		togglePause,
		pivot,
		setLocalDisplays,
		shareUrl,
		reportWebRTCEvent,
		reportWebRTCUserRating,
		start,
		end,
		isStreamSynced,
		stats,
	};
}
