import React, { useEffect, useMemo } from 'react';
import { useIntl } from 'react-intl';
import Settings from 'components/settings';
import { AppContext } from 'context/appContext';
import useFeedbackController from 'hooks/useFeedbackController';
import useHotkeyController from 'hooks/useHotkeyController';
import useNavController from 'hooks/useNavController';
import useSettingsController from 'hooks/useSettingsController';
import FeedbackOverlay from 'pages/feedbackOverlay';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './common.scss';
import Lobby from './pages/lobby';
import Session from './pages/session';
import { translationWithErrorHandling } from './translation/handleMissingTranslation';

const App: React.FC = () => {
	const intl = useIntl();

	const feedbackables = [
		{
			name: 'sessionEnd',
			title: translationWithErrorHandling(intl, 'app.howWasYourSession'),
			ratable: true,
			reviewable: true,
			postSubmitMessage: translationWithErrorHandling(intl, 'app.thankYouForYourFeedback'),
		},
		{
			name: 'GoBeSwitch',
			title: translationWithErrorHandling(intl, 'app.howWasTheSwitching'),
			ratable: true,
			reviewable: true,
			timeout: 5000,
			initialDelay: 3000,
			postSubmitMessage: translationWithErrorHandling(intl, 'app.thankYouForYourFeedback'),
		},
	];

	const settingsController = useSettingsController();
	useHotkeyController({ settingsController });
	const navController = useNavController();
	const feedbackController = useFeedbackController({
		feedbackables,
		settingsController,
	});

	// Disable dragging of images everywhere
	useEffect(() => {
		const callback = function (mutationsList: MutationRecord[]) {
			for (let mutation of mutationsList) {
				if (mutation.type === 'childList' && mutation.addedNodes.length > 0) {
					mutation.addedNodes.forEach((node: Node) => {
						if (node.nodeType === Node.ELEMENT_NODE) {
							const images = (node as HTMLElement).getElementsByTagName('img');
							for (let i = 0; i < images.length; i++) {
								images[i].draggable = false;
							}
						}
					});
				}
			}
		};
		const observer = new MutationObserver(callback);
		observer.observe(document, { attributes: true, childList: true, subtree: true });
	}, []);

	return (
		<AppContext.Provider value={{ settingsController, navController, feedbackController }}>
			<FeedbackOverlay />
			<Settings />
			<BrowserRouter>
				<Routes>
					<Route path="/" element={<Lobby />} />
					<Route path="/session" element={<Session />} />
				</Routes>
			</BrowserRouter>
		</AppContext.Provider>
	);
};

export default App;
