import React, { useState, useEffect, ReactNode } from 'react';
import { useIntl } from 'react-intl';
import greenGobeLogo from 'images/green-gobe-logo.svg';
import './ErrorBoundary.css';
import { translationWithErrorHandling } from '../translation/handleMissingTranslation';

interface ErrorBoundaryProps {
	children: ReactNode;
}

const ErrorBoundary: React.FC<ErrorBoundaryProps> = ({ children }) => {
	const [hasError, setHasError] = useState(false);
	const intl = useIntl();

	useEffect(() => {
		// This function handles errors globally and updates the state
		const handleError = (error: ErrorEvent) => {
			console.error('Error caught by ErrorBoundary:', error);
			setHasError(true);
		};

		window.addEventListener('error', handleError);

		return () => {
			window.removeEventListener('error', handleError);
		};
	}, []);

	if (hasError) {
		return (
			<div className="endSessionWrapper">
				<div className="container">
					<img src={greenGobeLogo} alt="" />
					<div className="session">
						{translationWithErrorHandling(intl, 'errorBoundary.sessionEnded')}
					</div>
					<div className="endMsg">
						{translationWithErrorHandling(intl, 'errorBoundary.theSessionHasEnded')}
					</div>
				</div>
			</div>
		);
	}

	return <>{children}</>;
};

export default ErrorBoundary;
