import React, { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';
import locales from './translation';
import { LocalSessionInfo } from 'types';
import flatten from 'flat';
import App from './App';
import ErrorBoundary from 'utils/ErrorBoundary';

const codes: any = locales.codes;
const messages: any = {
	en: locales.en,
	jp: locales.jp,
	dk: locales.dk,
	nl: locales.nl,
	br: locales.br,
};
const defaultLanguage = 'english';

const IntlAppWrapper: React.FC = () => {
	const sessionInfo = useMemo<LocalSessionInfo>(() => {
		return JSON.parse(decodeURIComponent(atob(window.location.search.split('=')[1])));
	}, []);
	const language = codes[sessionInfo.pilot.language ? sessionInfo.pilot.language : defaultLanguage];

	return (
		<IntlProvider locale={language} messages={flatten(messages[language])}>
			<ErrorBoundary>
				<App />
			</ErrorBoundary>
		</IntlProvider>
	);
};

export default IntlAppWrapper;
