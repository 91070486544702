import React from 'react';
import './index.scss';
import { FormattedMessage, useIntl } from 'react-intl';

const HelpCenterAnchor: React.FC = () => {
	return (
		<a
			className="urlLink"
			href="https://www.gobe-robots.com/help"
			target="_blank"
			rel="noopener noreferrer"
		>
			<FormattedMessage id="helpCenterAnchor.helpCenter" />
		</a>
	);
};

export default HelpCenterAnchor;
