import leftArrowIcon from 'images/chevron-back-circle-outline.svg';
import downArrowIcon from 'images/chevron-down-circle-outline.svg';
import rightArrowIcon from 'images/chevron-forward-circle-outline.svg';
import upArrowIcon from 'images/chevron-up-circle-outline.svg';
import React, { useCallback, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { useLongPress } from 'use-long-press';
import './index.scss';
import { translationWithErrorHandling } from '../../../../translation/handleMissingTranslation';

type PropsFromParent = {
	deviationStep: { x: number; y: number };
	dimensions: { width: number; height: number };
	callbacks: {
		setDeviationX: (value: number) => void;
		setDeviationY: (value: number) => void;
		exportOutput: () => void;
	};
};
type ComponentProps = PropsFromParent;

const VideoCalibrationOverlay: React.FC<ComponentProps> = ({
	deviationStep,
	dimensions,
	callbacks,
}) => {
	const intl = useIntl();
	const [deviationXStep] = useState<number>(deviationStep.x);
	const [deviationYStep] = useState<number>(deviationStep.y);
	const borderStyle: React.CSSProperties = {
		position: 'absolute',
		display: 'flex',
		width: `${dimensions.width}px`,
		height: `${dimensions.height}px`,
		alignItems: 'center',
		justifyContent: 'center',
		border: 'solid white',
		boxSizing: 'border-box',
		backgroundColor: 'transparent',
		zIndex: 5,
	};

	let longPressCallbackIntervalRef = useRef<any>();
	const longPressContext = {
		onFinish: () => {
			clearInterval(longPressCallbackIntervalRef.current!);
		},
		onCancel: () => {
			clearInterval(longPressCallbackIntervalRef.current!);
		},
		threshold: 600,
		captureEvent: true,
		cancelOnMovement: false,
	};

	return (
		<div style={borderStyle}>
			<div className="center-point" />
			<div className="control-overlay">
				<div
					onClick={() => {
						callbacks.setDeviationX(-1 * deviationXStep);
					}}
					{...useLongPress(
						React.useCallback(() => {}, []),
						{
							onStart: useCallback(() => {
								clearInterval(longPressCallbackIntervalRef.current!);
								longPressCallbackIntervalRef.current = setInterval(
									() => callbacks.setDeviationX(-1 * deviationXStep),
									100
								);
							}, [callbacks, deviationXStep]),
							...longPressContext,
						}
					)()}
				>
					<img className="controlButton" src={leftArrowIcon} alt="" />
				</div>
				<div className="control-overlay-col">
					<div
						onClick={() => {
							callbacks.setDeviationY(deviationYStep);
						}}
						{...useLongPress(
							React.useCallback(() => {}, []),
							{
								onStart: useCallback(() => {
									clearInterval(longPressCallbackIntervalRef.current!);
									longPressCallbackIntervalRef.current = setInterval(
										() => callbacks.setDeviationY(deviationYStep),
										200
									);
								}, [callbacks, deviationYStep]),
								...longPressContext,
							}
						)()}
					>
						<img className="controlButton" src={upArrowIcon} alt="" />
					</div>
					<div
						onClick={() => {
							callbacks.setDeviationY(-1 * deviationYStep);
						}}
						{...useLongPress(
							React.useCallback(() => {}, []),
							{
								onStart: useCallback(() => {
									clearInterval(longPressCallbackIntervalRef.current!);
									longPressCallbackIntervalRef.current = setInterval(
										() => callbacks.setDeviationY(-1 * deviationYStep),
										200
									);
								}, [callbacks, deviationYStep]),
								...longPressContext,
							}
						)()}
					>
						<img className="controlButton" src={downArrowIcon} alt="" />
					</div>
				</div>
				<div
					onClick={() => {
						callbacks.setDeviationX(deviationXStep);
					}}
					{...useLongPress(
						React.useCallback(() => {}, []),
						{
							onStart: useCallback(() => {
								clearInterval(longPressCallbackIntervalRef.current!);
								longPressCallbackIntervalRef.current = setInterval(
									() => callbacks.setDeviationX(deviationXStep),
									200
								);
							}, [callbacks, deviationXStep]),
							...longPressContext,
						}
					)()}
				>
					<img className="controlButton" src={rightArrowIcon} alt="" />
				</div>
				<div
					className="outputButton"
					onClick={() => {
						callbacks.exportOutput();
					}}
				>
					{translationWithErrorHandling(intl, 'videoCalibrationOverlay.exportOutput')}
				</div>
			</div>
		</div>
	);
};

export default VideoCalibrationOverlay;
