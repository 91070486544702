import './index.scss';
import { FormattedMessage } from 'react-intl';

type Props = {
	size?: 'small' | 'medium' | 'large';
};

const BetaLabel = ({ size = 'medium' }: Props) => {
	// TODO: implement sizing
	return (
		<div className="chip">
			<p className="chip-label">
				<FormattedMessage id="betalabel.beta" />
			</p>
		</div>
	);
};

export default BetaLabel;
